.redirect {
  display: flex;
  justify-content: center;
  padding: 30px;
  .redirect-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 50px;
    flex-direction: column;
    img {
      width: 200px;
    }
    h5 {
      font-weight: bold;
      margin-top: 14px;
    }
    p {
      text-align: center;
      font-size: 12px;
    }
    .loader {
      width: 8px;
      height: 40px;
      border-radius: 4px;
      display: block;
      margin: 20px auto;
      position: relative;
      background: currentColor;
      color: #fbb12f;
      box-sizing: border-box;
      position: absolute;
      animation: animloader 0.3s 1s linear infinite alternate;
    }

    .loader::after,
    .loader::before {
      content: '';
      width: 8px;
      height: 40px;
      border-radius: 4px;
      background: currentColor;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 20px;
      box-sizing: border-box;
      animation: animloader 0.2s 0.45s linear infinite alternate;
    }
    .loader::before {
      left: -20px;
      animation-delay: 0s;
    }

    @keyframes animloader {
      0% {
        height: 48px;
      }
      100% {
        height: 6px;
      }
    }
  }
}
