.swal-text {
	text-align: center !important;
}

.popup-overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	text-align: center;
	font-size: 0;
	overflow-y: auto;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 999;
  
	.popup-container {
	  height: 300px;
	  width: 80vw;
	  max-width: 330px;
	  background-color: white;
	  text-align: center;
	  border-radius: 5px;
	  position: static;
	  margin: 20px auto;
	  display: inline-block;
	  vertical-align: middle;
	  transform-origin: 50% 50%;
	  z-index: 1000;
	  padding: 35px 20px;
	  margin-top: calc((100vh - 300px) / 2) !important;
  
	  .image-pop {
		width: 70%;
		max-width: 140px;
	  }
  
	  .text-soon {
		margin-top: 25px;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: 0.57px;
		text-align: center;
		color: #231f20;
	  }
  
	  .button-cont {
		height: 30px;
		margin-top: 25px;
		padding: 5px;
		text-align: center;
		border-radius: 3px;
		background-color: #231f20;
		width: 80px;
		cursor: pointer;
		margin-left: auto;
		margin-right: auto;
  
		.text-button {
		  font-size: 14px;
		  font-weight: bold;
		  font-stretch: normal;
		  font-style: normal;
		  line-height: normal;
		  letter-spacing: 0.5px;
		  text-align: center;
		  color: #ffde17;
		}
	  }
	}
  }