.footer-pay {
  // background-color: #ffde17;
  .footer-button {
    background-color: #ebebeb;
    justify-content: center;
    align-items: center;
    bottom: 0.5px;
    max-width: 420px;
    .submit-button {
      background-color: #231f20;
      border: none;
      color: #ffde17;
      width: 92.5%;
      height: 50px;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.48px;
      text-align: center;
      border-radius: 4px;
    }

    .version {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #b8b8b8;
      text-align: center;
    }
  }
}

.rowCardList {
  padding: 5px 20px;
  border-radius: 8px;
  background-color: #e9e6e668;
}

.countDown {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.header-name {
  display: flex;
  // width: 100%;
  align-items: center;
  justify-content: space-between;
  b {
    font-size: 15px;
  }
  .Countdown {
    display: flex;
    font-size: 12px;
    align-items: center;
    justify-content: center;
  }
}

.countdown-cont {
  background-color: #fdedee;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #f23b3b;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.text {
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.54px;
}

.header-drop {
  position: relative;
  width: 60px;
}
.drop-card {
  position: absolute;
  top: 50px;
  right: 40px;
  width: 15%;
  height: auto;
  background-color: #b8b8b8;
  color: black;
  padding: 10px;
  .drop-item {
    cursor: pointer;
  }
}

.totalAmount {
  width: 324px;
  object-fit: contain;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-top: 5px;
  color: #fff;
}

.item {
  strong {
    font-weight: 900;
    color: #231f20;
  }
}

@media only screen and (max-width: 320px) {
  .b-payment {
    margin-bottom: 10rem;
  }
}

.buttonSubmit {
  width: 100%;
  margin-left: 16px !important;
  width: 92%;
  margin-left: auto;
  margin-right: auto;

  background: #231f20;
  outline: none;
  border-radius: 4px;
  padding: 10px;
  color: #fbb12f;
  margin-top: auto;
  border: none;
}

.footer {
  padding-top: 5px;
}

.version {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b8b8b8;
  font-size: 12px;
  padding-bottom: 15px;
}

@media only screen and (max-width: 568px) {
  .version {
    padding-bottom: 5px !important;
  }
}
