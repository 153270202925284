.not-found-text {
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.51px;
  text-align: center;
  color: #fbb12f;
  margin-top: 15px;
  width: 70%;
  text-align: center;
  word-break: keep-all;
}
