body {
  font-family: 'Inter', sans-serif !important;
  background: url('./assets/BG3\ \(1\).png');
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: hidden;
  height: 100vh !important;
  color: #231f20;
}

.offline-dialog {
  &.modal-dialog {
    max-width: 420px !important;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    .modal-content {
      border: none;
      border-radius: 0px;
    }

    .text-offline {
      text-align: center;
      color: red;
      font-size: 12px;
    }
  }
}

.swal-text {
  text-align: center !important;
}

::-webkit-scrollbar {
  width: 3px;
  height: 1px !important;
  background-color: none;
}

::-webkit-scrollbar-thumb {
  background: #fbb02f51;
}

::-webkit-scrollbar-track {
  background: none;
}

@media only screen and (min-width: 720px) {
  .offline-dialog {
    &.modal-dialog {
      margin-top: calc((100vh - 720px) / 2) !important;
    }
  }
}
@media only screen and (max-width: 720px) {
  body {
    overflow: hidden;
  }
}
@media only screen and (min-width: 568px) {
  .apps {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.cursor {
  cursor: pointer;
}

.white {
  background-color: #ffffff;
}

.paddingBody {
  padding: 10px 22px;
}
